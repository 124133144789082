import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom'; 

import { loginWithUsernameAndPassword } from '@/actions/login';

import i18n from '@/i18n';
import { Button, Box, TextField, Divider } from '@mui/material' 

export function LoginWithPasswordPage() {
  const navigate = useNavigate()
  const [loggingIn, setLoggingIn] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  
  const [loggedIn, setLoggedIn] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoggingIn(true)
    localStorage.setItem('email', email);
    loginWithUsernameAndPassword(email, password).then((data) => {
      setError(null)
      setLoggingIn(false)
      navigate(`/dashboard`);
    }).catch(e => {
      console.error(e)
      setLoggingIn(false)
      setError(e)
    })
  }
  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box sx={{ padding: 1 }}>
        <Box style={{ display: 'flex', gap: 10, width: '100%', alignItems: 'stretch', flexDirection: "column"}}>
          <TextField placeholder={i18n.t("email")} onChange={(event) => setEmail(event.target.value)} name="email" variant="standard" label={i18n.t("email")} required={true} />
          <TextField placeholder={i18n.t("password")} onChange={(event) => setPassword(event.target.value)} name="password" type="password" variant="standard" label={i18n.t("password")} required={true} />
          <Button loading={loggingIn} type="submit" variant="contained" color="primary">{loggingIn ? i18n.t('loading') : i18n.t('login')}</Button>
          <Divider sx={{ '--Divider-childPosition': '50%' }}>{i18n.t('or')}</Divider>
          <Button onClick={() => navigate(`/accounts/login`)}>{i18n.t('login-with-link-instead')}</Button>
          <Button variant="outlined" onClick={() => window.open(`/accounts/password_reset/`)}>{i18n.t('forgot-password')}?</Button>
        </Box>
      </Box>
    </form>
  )
}

export default LoginWithPasswordPage;