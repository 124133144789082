import React, { useCallback, useEffect, useRef, useState } from 'react';

import { LoadingPage } from '../LoadingPage';
import { gql, useMutation } from '@apollo/client';
import { makeUniqueId } from '@apollo/client/utilities';

const MUTATION = gql`
  mutation createMagicLink($nonce: String!) {
    createMagicLink(nonce: $nonce) {
      magicLink {
        token
      }
    }  
  }
`

export const Doorframe = React.forwardRef(({
  src,
  origin,
  onPageAction=(event) => {},
  onLoad = (event)=> {},
  iframeProps = {},
  ...props
}, ref) => {
  const [token, setToken] = useState(false)
  
  const iframeEl = useRef(null)

  const [createMagicLink, { loading, data }] = useMutation(MUTATION, {
    onCompleted: (data) => {
      setToken(data?.createMagicLink?.magicLink?.token)
    }
  })

  useEffect(() => {
    createMagicLink({
      variables: {
        nonce: makeUniqueId()
      }
    }).then(magicLinkResult => {
      
    }).catch(e => {

    })
  }, [])
  
  const handlePageAction = useCallback((event) => {
    onPageAction(event)
    if (event.data?.action === 'load') {
      onLoad(event)
    }
  }, [onLoad, onPageAction])

  useEffect(() => {
    if (iframeEl.current) {
      window.addEventListener('message', (event) => {
        if (event.origin !== origin) {
          return
        }
        if (event.action) {
          handlePageAction(event)
        }
      })
    }
  }, [iframeEl, handlePageAction, origin])
  return (
    <div {...props} ref={ref}>
      {loading &&
        <LoadingPage />
      }
      {token &&
        <iframe title={src} ref={iframeEl} src={`${import.meta.env.VITE_BACKEND_URL}/magic/token?token=${token}&redirectUrl=${encodeURIComponent(src)}`} frameborder={0}  {...iframeProps}></iframe>
      }
    </div>
  )
})

