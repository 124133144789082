import React from 'react'

import { StitchApp } from '@/components/StitchApp'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Card, Container, Grid, Link, Paper, Typography } from '@mui/material'
import { ErrorView } from '@/components/ErrorView'

import { Link as ReactLink } from 'react-router-dom'
import { CardImage, CardTitle } from '@/components/Card'
import { InstallAppButton } from '@/components/InstallAppButton'
import { gql } from '@apollo/client'
import { LoadingPage } from '@/components/LoadingPage'
import { Cube } from '@phosphor-icons/react'

import i18n from '@/i18n'
import { HBox } from '@/components/HBox'
import { VBox } from '@/components/VBox'

const QUERY = gql`
  query {
    appfinder {
      apps {
        id
        installation {
          id
          installed
        }
        description
        slug
        imageUrl
        name
      }
    }
  }
`

export function AppFinderPage() {
  return (
    <AppFinderContainer />
  )
}

export function AppFinderContainer() {

  const { data, refetch, loading } = useQuery(
    QUERY
  )

  if (loading) {
    return (
      <LoadingPage />
    )
  }

  if (!data) {
    return (
      <ErrorView />
    )
  }

  const apps = data.appfinder.apps

  return (
    <Container>
      <Box sx={{ backgroundColor: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '16/4' }}>
        <HBox>
          <img src="/images/ALOADED_Logo.png" style={{ height: 50, filter: 'invert(1)' }} /> <Typography color="white" level="h1" sx={{ fontSize: 50 }}>Apps</Typography>
        </HBox>
        
      </Box>
      <br />
      <Paper>
        <Grid container>
          {apps.map(app => (
            <Grid item sm={6} md={4} lg={4}>
              <Link as={ReactLink} to={`../${app.slug}`}>
                <HBox sx={{ alignItems: 'flex-start' }} elevation={0}>
                  {app.imageUrl ? <CardImage src={app.imageUrl} /> : <Cube size={128} />}
                  <VBox sx={{ flex: 1 }}>
                    <HBox>
                      <Typography sx={{ flex: 1 }}>{app.name}</Typography>
                      <Button variant="contained" color="primary" onClick={() => navigate(`/dashboard/app/${app.slug}`)}>{i18n.t('view')}</Button>
                    </HBox>
                    <Typography>{app.description}</Typography>
                  </VBox>
                </HBox>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  )
}

export default AppFinderPage