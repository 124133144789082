import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import localForage from 'localforage';

import fragments from './fragments';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

console.log(import.meta.env.VITE_BACKEND_URL)

export const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();


const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_BACKEND_URL}/graphql/`,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await localForage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

/*
export const authorizedFetch = async (method, path, params, defaultQuery) => {
  let query = {}
  for (let k in defaultQuery) {
    if (defaultQuery[k]) {
      query[k] = defaultQuery[k]
    }
  }
  const res = await fetch(
    `${getAPIUrl()}/api/v2${path}?${new URLSearchParams(query).toString()}`, {
    ...params,
    method,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await localForage.getItem('token')}`,
      ...params?.headers
    }
  })

  if (res.status < 200 && res.status > 299) {
    throw new Error(res.status)
  }
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(params?.method)) {
    await queryClient.invalidateQueries()
  }

  return await res.json()
}

export const authorizedCoreFetch = async (method, path, params, defaultQuery) => {
  let query = {}

  for (let k in defaultQuery) {
    if (typeof defaultQuery[k] !== 'undefined') {
      query[k] = defaultQuery[k]
    }
  }

  const res = await fetch(
    `${getAPIUrl()}/api/v2${path}?${new URLSearchParams(query).toString()}`, {
    ...params,
    method,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await localForage.getItem('token')}`,
      ...params?.headers
    }
  })

  if (res.status < 200 && res.status > 299) {
    throw new Error(res.status)
  }
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(params?.method)) {
    await queryClient.invalidateQueries()
  }

  return res
}
*/

export const client = new ApolloClient({
  link: link.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(
      fragments
    )
  })
});

export default client

