import { gql, useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { Button } from '@mui/material'

import i18n from '@/i18n'

import React from 'react'

const INSTALL_APP_QUERY = gql`
  mutation installApp($slug: String!) {
    installApp(slug: $slug) {
      installation {
        id
      }
    }
  }
`

const UNINSTALL_APP_QUERY = gql`
  mutation uninstallApp($slug: String!) {
    uninstallApp(slug: $slug) {
      installation {
        id
      }
    }
  }
`

const QUERY = gql`
  query getAppBySlug($slug: String!) {
    appfinder {
      app(slug: $slug) {
        id
        slug
        name
        installation {
          id
          installed
        }
      }
    }
  }
`

export function InstallAppButton({
  slug,
  onChange = () => {}
}) {

  const {
    data,
    loading,
    refetch
  } = useQuery(
    QUERY,
    {
      variables: {
        slug
      },
      onCompleted: () => {
        onChange()
      }
    }
  )

  const [installApp, {
    loading: installing
  }] = useMutation(
    INSTALL_APP_QUERY,
    {
      onCompleted: () => {
        refetch()
      }
    }
  )

  const [uninstallApp, {
    loading: uninstalling
  }] = useMutation(
    UNINSTALL_APP_QUERY,
    {
      onCompleted: () => {
        refetch()
      }
    }
  )

  const toggleInstallApp = (slug) => {
    if (installing || uninstalling) {
      return
    }
    if (app.installation) {
      uninstallApp({
        variables: {
          slug
        }
      })
    } else {
      installApp({
        variables: {
          slug
        }
      })
    }
  }

  if (loading) {
    return <></>
  }
  if (!data) {
    return <>E</>
  }
  const app = data.appfinder.app
  return (
    <Button loading={installing || uninstalling} variant={app.installation ? 'outlined': 'contained'} color="primary" disabled={installing || uninstalling} onClick={(event) => { event.preventDefault(); toggleInstallApp(app.slug); return false }}>{i18n.t(installing ? 'installing' : uninstalling ? 'uninstalling' : app.installation ? 'installed' : 'install')}</Button>
  )
}