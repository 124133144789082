import React, { useEffect, useState } from 'react';

import { useLoaderData, useParams } from 'react-router-dom';
 
import dayjs from 'dayjs'
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';

import i18n from '@/i18n';
import { UserView } from '@/components/UserView';

import { LEAD_STATUS_CODES } from '@/constants'

const ADD_MESSAGE_MUTATION = gql`
  mutation addMessageToLead(
    $logged: Date!,
    $slug: String!,
    $text: String!,
    $status: String!
  ) {
    addMessageToLead(
      slug: $slug,
      text: $text,
      status: $status,
      logged: $logged
    ) {
      message {
        slug
      }
    }
  }
`

const QUERY = gql`query getLead($slug: String!) { 
  leads {
    lead(slug: $slug) {
      ...LeadFields
      contacted,
      contractValueInEur
      messages {
        created
        slug
        status
        user {
          username
          imageUrl
        }
        text
      }
    }
  }
}`

const MUTATION = gql`mutation editLead(
  $slug: String!,
  $isBlocked: Boolean,
  $notes: String,
  $email: String,
  $whoUsername: String,
  $contacted: Date,
  $shouldContact: Date,
  $insight: String,
  $socials: String,
) {
  editLead(
    slug: $slug,
    notes: $notes,
    isBlocked: $isBlocked,
    email: $email,
    contacted: $contacted,
    shouldContact: $shouldContact,
    whoUsername: $whoUsername,
    insight: $insight,
    socials: $socials
  ) {
    lead {
      slug
    }
  }
}`

export function EditLeadMessagesPage() {
  const { slug } = useParams()
  return <EditLeadMessages slug={slug} />
}

export function EditLeadMessages({
  onUpdated = () => {},
  slug
}) {
  const [newMessage, setNewMessage] = useState('')
  const { data } = useQuery(QUERY, {
    variables: {
      slug
    }
  })

  const [status, setStatus] = useState(0)

  const [addMessageToLead, { data: addMessageData, loading: addingMessage, error: addMessageError }] = useMutation(
    ADD_MESSAGE_MUTATION, {
      variables: {
        slug: slug,
        text: newMessage
      },
      onCompleted: () => {
        //().then(() => {})
      }
    }
  )

  const [logged, setLogged] = useState(dayjs())

  const lead = data?.leads?.lead

  const handleSubmitMessage = (event) => {
    event.preventDefault()
    addMessageToLead({
      variables: {
        slug: slug,
        logged: logged?.format('YYYY-MM-DD'),
        text: newMessage,
        status
      }
    }).then(() => {

    })
    return false
  }
  return (
    <>
      <Paper sx={{ padding: 1 }}>
        <form onSubmit={handleSubmitMessage}>
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <TextField
                multiline
                sx={{ flex: 2 }}
                fullWidth
                value={newMessage}
                variant="standard"
                label={i18n.t("Message")}
                onChange={(event) => setNewMessage(event.target.value)}
              />
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center', flexDirection: 'row' }}>
                <DatePicker
                  labelId={i18n.t("date")} 
                  label={i18n.t("Date")}
                  sx={{
                    width: '100%'
                  }}
                  variant="standard"
                  value={dayjs(logged)}
                  onChange={(value) => {
                    setLogged(value)
                  }}
                />
                <Box sx={{ flex: 1 }} />
                <InputLabel>{i18n.t('Status')}</InputLabel>
                <FormControl sx={{ width: 200}}>
                  <Select
                    id="select-status"
                    variant="outlined"
                    value={status}
                    sx={{
                      width: '100%'
                    }}
                    onChange={(event) => {
                      setStatus(event.target.value)
                    }}
                  >
                    <MenuItem value="">{i18n.t('Select status')}</MenuItem>
                    {LEAD_STATUS_CODES.map(status => (
                      <MenuItem key={status.slug} value={status.slug}>{i18n.t(status.name)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              
                <FormControl sx={{ width: 200}}>
                  <Select
                    id="select-status"
                    variant="outlined"
                    value={status}
                    sx={{
                      width: '100%'
                    }}
                    onChange={(event) => {
                      setStatus(event.target.value)
                    }}
                  >
                    <MenuItem value="">{i18n.t('Select status')}</MenuItem>
                    {LEAD_STATUS_CODES.map(status => (
                      <MenuItem key={status.slug} value={status.slug}>{i18n.t(status.name)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={addingMessage}
              >
                {i18n.t('Add log')}
              </LoadingButton>
            </Box>
            <Box sx={{flexDirection: 'column', display: 'flex', gap: 1}}>
            </Box>
          </Box>
        </form>
      </Paper>
      {lead?.messages?.map(message => (
        <Paper sx={{ padding: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="body1">{message.text}</Typography>
            <Typography variant="caption">{dayjs(message.created).format('YYYY-MM-DD HH:mm')}</Typography>
            <Typography variant="caption">{i18n.t(message.status)}</Typography>
            <UserView user={message?.user} />
          </Box>
        </Paper>
      ))} 
    </>
  )
}
