import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';

import { Tabs, Tab, Box, FormControl, InputLabel, Select, MenuItem, Fab, TextField, FormControlLabel, Switch, Button, Typography } from '@mui/material';

import _ from 'lodash'

import { enqueueSnackbar } from 'notistack';

import i18n from '@I18n'; 
import { Container } from '@mui/system';
import { useNavigate } from 'react-router'
import { Page } from '../../../components/Page';
import { LoadingButton } from '@mui/lab';
import { LabelTable } from '../../../components/LabelTable';
import { LoadingPage } from '../../../components/LoadingPage';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const MUTATION = gql`mutation performActionOnLabels($action: String!, $slugs: [String]!) {
  performActionOnLabels(action: $action, slugs: $slugs) {
    labels {
      slug,
      name
    }
  }
}`

const QUERY = gql`
query getLabels($isBlocked: Boolean, $q: String) {
  leads {
    labels(isBlocked: $isBlocked, q: $q) {
      slug
      name
      labels {
        slug
        name
      }
    }
  }
}`

export default function LabelListPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [action, setAction] = useState('')
  const [checkedLabelIds, setCheckedLabelIds] = useState([])
  const isBlocked =  JSON.parse(searchParams.get('isBlocked'))
  const q = searchParams.get('q')
  const { data, loading, refetch } = useQuery(
    QUERY, {
      variables: {
        q,
        isBlocked 
      },
      onCompleted: () => {
        
      },
      onError: () => {
        enqueueSnackbar(t('Error loading labels'), {
          variant: 'error'
        })
      }
    }
  )
  const [
    performActionOnLabels,
    {
      data: performing,
      error: mutationError,
      refetch: mutationRefetch
    }
  ] = useMutation(MUTATION, {
    onComplete: () => {
      refetch().then(() => {
        refetch().then(() => {})
        enqueueSnackbar(t('Action performed'), {
          variant: 'success'
        })
      })
    },
    onError: () => {
      enqueueSnackbar(t('Error performing action'), {
        variant: 'error'
      })
    }
  });

  const handleSubmitAction = (event) => {
    event.preventDefault()
    performActionOnLabels({
      variables: {
        action,
        slugs: checkedLabelIds
      }
    })
  }

  const handleAddLabelClick = (event) => {
    event.preventDefault()
    navigate('/dashboard/label/add')
  }

  return (
    <>
      <Container>
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", alignItems: "center", gap: 1 }}> 
          <Typography variant="h5" sx={{ flex: 1 }}>{i18n.t('Labels')}</Typography>
          <Button onClick={handleAddLabelClick} variant="contained" color="primary" aria-label="Create label">
            Add label
          </Button>
        </Box>
      </Container>
      <Container>
        <Page>
          <form onSubmit={handleSubmitAction}>
            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", alignItems: "center", gap: 1 }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>{i18n.t('Action')}</InputLabel>
                <Select
                  value={action} 
                  onChange={(event) => {
                    setAction(event.target.value)
                  }}
                >
                  <MenuItem value={''}>{i18n.t('Select action')}</MenuItem>
                  <MenuItem value={'block'}>{i18n.t('Block')}</MenuItem>
                  <MenuItem value={'unblock'}>{i18n.t('Unblock')}</MenuItem>
                </Select>
              </FormControl>
              <LoadingButton type="submit" variant="contained" loading={performing} disabled={checkedLabelIds.length === 0 || action === null}>{i18n.t('do')}</LoadingButton>
              <Box sx={{ flexGrow: 1 }} />
              <FormControlLabel
                control={
                  <Switch aria-label={i18n.t("Show blocked")} defaultValue={isBlocked} onChange={(event) => setSearchParams({ isBlocked: event.target.checked, q: q ?? '' }, { replace: true })} />
                }
                label={i18n.t('Show blocked')}
              />
              <TextField label={i18n.t('Search')} variant="standard" value={q} onChange={(event) => setSearchParams({ q: event.target.value, isBlocked }, { replace: true })} />
            </Box>
          </form>
          {data?.leads?.labels instanceof Array && (
            <LabelTable
              labels={data?.leads?.labels.slice(0, 50)}
              onNodesChecked={({ nodes }) => {
                setCheckedLabelIds(nodes.map(n => n.slug))
              }}
            />
          )}
          {loading && (
            <LoadingPage />
          )}
        </Page>
      </Container>
    </>
  ) 
} 