import React from 'react';

import {Image} from '..'


export function CardImage({
  src
}) {
  return (
    <Image src={src} width="100%" />
  )
}