import { useState } from 'react';

import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';

import i18n from '@/i18n';

import { LEAD_STATUS_CODES } from '@/constants';
import { gql } from '@apollo/client';

const ADD_MESSAGE_MUTATION = gql`
  mutation addMessageToLead(
    $logged: Date!,
    $slug: String!,
    $text: String!,
    $status: String!
  ) {
    addMessageToLead(
      slug: $slug,
      text: $text,
      status: $status,
      logged: $logged
    ) {
      message {
        slug
      }
    }
  }
`

export function AddLogToLeadContainer({
    slug,
    onSaved
}) {
    const [text, setText] = useState('')

    const [status, setStatus] = useState(0)

    const [addMessageToLead, { data: addMessageData, loading: addingMessage, error: addMessageError }] = useMutation(
        ADD_MESSAGE_MUTATION,
        {
            variables: {
                slug,
                text
            },
            onCompleted: () => {
            }
        }
    )

    const [logged, setLogged] = useState(dayjs())

    const handleSubmitMessage = (event) => {
        event.preventDefault()
        addMessageToLead({
            variables: {
                slug: slug,
                logged: logged?.format('YYYY-MM-DD'),
                text,
                status
            }
        }).then((data) => {
            onSaved?.(data)
        })
        return false
    }

    return (
        <form onSubmit={handleSubmitMessage}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        multiline
                        sx={{ flex: 2 }}
                        fullWidth
                        value={text}
                        variant="standard"
                        label={i18n.t("Message")}
                        onChange={(event) => setText(event.target.value)}
                    />
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexDirection: 'row' }}>
                        <DatePicker
                            labelId={i18n.t("date")}
                            label={i18n.t("Date")}
                            sx={{
                                width: '100%'
                            }}
                            variant="standard"
                            value={dayjs(logged)}
                            onChange={(value) => {
                                setLogged(value)
                            }}
                        />
                        <Box sx={{ flex: 1 }} />
                        <InputLabel>{i18n.t('Status')}</InputLabel>
                        <FormControl sx={{ width: 200 }}>
                            <Select
                                id="select-status"
                                variant="outlined"
                                value={status}
                                sx={{
                                    width: '100%'
                                }}
                                onChange={(event) => {
                                    setStatus(event.target.value)
                                }}
                            >
                                <MenuItem value="">{i18n.t('Select status')}</MenuItem>
                                {LEAD_STATUS_CODES.map(status => (
                                    <MenuItem key={status.slug} value={status.slug}>{i18n.t(status.name)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={addingMessage}
                    >
                        {i18n.t('Add log')}
                    </LoadingButton>
                </Box>
                <Box sx={{ flexDirection: 'column', display: 'flex', gap: 1 }}>
                </Box>
            </Box>
        </form>
    )
}