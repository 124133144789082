
import React from 'react';
 
import { Dialog } from '@mui/material';

export const DialogContext = React.createContext({
  open: false
})

export function DialogTrigger({
  children,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const close = (event) => {
    setOpen(false)
  }
  const handleEntering = () => { 
  }
 
  return (
    <DialogContext.Provider value={{ open, setOpen }}>
      {children[0]}
      {children[1](close)}
    </DialogContext.Provider>
  )
}