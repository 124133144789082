import React, { useContext, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import i18n from '@I18n';
import { NodeTable } from '../NodeTable';
import { TrackPlayButton } from '../TrackPlayButton';
import { Avatar, Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Img } from '../Img';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DashboardLayoutContext } from '../../pages/dashboard/layout';
import { AssignLeadSelect } from '../AssignLeadSelect';
import { ContactLeadButton } from '../ContactLeadButton';
import { Medal } from '@phosphor-icons/react';
import { TrendArrow } from '../TrendArrow';
import moment from 'moment';
import { AddLogToLeadContainer } from '@/pages/dashboard/lead/[slug]/messages/add';

export const EDIT_LEAD_MUTATION = gql`mutation editLead(
  $slug: String!,
  $whoUsername: String
) {
  editLead(slug: $slug, whoUsername: $whoUsername) {
    lead {
      slug
    }
  }
}`

export const ASSIGN_LEAD_MUTATION = gql`mutation assignLead(
  $slug: String!,
  $username: String
) {
  assignLead(slug: $slug, username: $username) {
    lead {
      slug
    }
  }
}`

const PERFORM_ACTION_ON_LEADS_MUTATION = gql`
mutation performActionOnLeads($slugs: [String!]!, $action: String!) {
  performActionOnLeads(slugs: $slugs, action: $action) {
    leads {
      slug
      contacted
    }
  } 
}`

const USERS_QUERY = gql`
  query {
    moodler {
      users {
        imageUrl
        firstName
        lastName
        username
      }
    }
  }
`

export function LeadlistEntryTable({
  entries,
  onRefetch = () => {},
  emptyText = '',
  showContactButton=false,
  showWho=false,
  emptyHeading = '',
  onCircleBackClicked = () => { console.error('onCircleBackClicked not implemented')},
  emptyIcon = InfoOutlined,
  expanded = false,
  canDelete,
  onDeleteClicked = () => {},
  onNodesChecked = ({ nodes }) => [],
  displayColumns=[]
}) {
  const navigate = useNavigate()
  console.log(entries)
  const [selectedNodeIds, setSelectedNodeIds] = React.useState({})
  const [addingLogToLead, setAddingLogToLead] = React.useState(null)
  const {
    unreadNotices
  } = useContext(DashboardLayoutContext)
  const [
    editLead, 
    {
      data: editLeadData
    }
  ] = useMutation(
    EDIT_LEAD_MUTATION,
    {
      onCompleted: () => {
        onRefetch()
      }
    }
  )
  const { data: usersData } = useQuery(USERS_QUERY)

  const [performActionOnLeads] = useMutation(PERFORM_ACTION_ON_LEADS_MUTATION, {
    onCompleted: () => {
      onRefetch()
    }
  })

  const [assignLead] = useMutation(ASSIGN_LEAD_MUTATION, {
    onCompleted: () => {
      onRefetch()
    }
  })

  useEffect(() => {
    if (unreadNotices?.find(n => !n.delivered)) {
      onRefetch()
    }
  }, [unreadNotices])

  const users = usersData?.moodler?.users ?? []

  const allColumns = [
    {
      id: 'player',
      label: i18n.t('player'),
      render: ({ node }) => {
        console.log(node?.lead)
        return (
          node?.lead?.track && <TrackPlayButton node={node.lead} value={node?.lead?.track} />
        )
      }
    },
    {
      id: 'image',
      label: i18n.t('Image'),
      render: ({ node }) => {
        return <Avatar src={node?.lead?.imageUrl} alt={node?.lead?.name} height="30pt" style={{ aspectRatio: '1/1'}} />
      }
    },
    {
      id: 'name',
      headerStyle: {
        textAlign: 'left',
        width: '30pt'
      },
      name: i18n.t('Name'),
      render: ({ node }) => {
        return <Link to={`/dashboard/lead/${node.lead?.slug}`}>{node.lead?.name}</Link>
      }
    },
    {
      id: 'label',
      headerStyle: {
        textAlign: 'left',
        width: '30pt'
      },
      name: i18n.t('Label'),
      render: ({ node }) => {
        return <Link to={`/dashboard/label/${node.lead?.label?.slug}`}>{node.lead?.label?.name}</Link>
      }
    },
    {
      id: 'spotifyUrl',
      name: i18n.t('Spotify'),
      render: ({ node }) => {
        const { lead } = node
        return (
          <a href={lead.spotifyUrl} target="__blank">
            <img alt="" src="/images/spotify.svg" height="30pt" />
          </a>
        )
      }
    },
    {
      id: 'label',
      headerStyle: {
        textAlign: 'left',
        width: '30pt'
      },
      label: i18n.t('Label'),
      render: ({ node }) => {
        return (
          <Link to={`/dashboard/label/${node.lead?.artist?.label?.slug}`}>{node.name}</Link>
        )
      }
    },
    {
      id: 'country',
      name: i18n.t('Country'),
      render: ({ node }) => {
        const lead = node?.lead
        return (
          <Img
            alt={lead?.country?.name}
            fallbackSrc={null}
            src={`https://flagcdn.com/32x24/${lead?.country?.identifier?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/32x24/${lead?.country?.identifier?.toLowerCase()}.png 2x,
                  https://flagcdn.com/48x36/${lead?.country?.identifier?.toLowerCase()}.png 3x`}

          />
        )
      }
    },
    {
      id: 'monthlyListeners',
      type: 'number',
      name: i18n.t('Monthly listeners'),
      headerStyle: {
        textAlign: 'right',
        width: '30pt'
      },
      render: ({ node }) => {
        const { lead } = node
        return (
          <span style={{ fontFamily: 'Courier'}}>{lead.monthlyListeners?.toLocaleString()}</span>
        )
      }
    },
    {
      id: 'monthlyGrowthPercent',
      type: 'number',
      name: i18n.t('Monthly growth percent'),
      headerStyle: {
        textAlign: 'right',
        width: '30pt'
      },
      render: ({ node }) => {
        const { lead } = node
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <TrendArrow value={lead.monthlyGrowthPercent} /> 
            <span style={{ fontFamily: 'Courier'}}>{lead.monthlyGrowthPercent?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%</span>
          </Box>
        )
      }
    },
    ...(showContactButton ? [{
      id: 'mark_as_contacted',
      name: i18n.t('Mark as contacted'),
      render: ({ node }) => {
        const lead = node?.lead
        return (
          <ContactLeadButton users={users} lead={lead} />
        )
      }
    }] : []),
    ...(showWho ? [{
      id: 'who',
      width: '130pt',
      name: i18n.t('Who'),
      render: ({ node }) => {
        const lead = node?.lead
        return (
          <AssignLeadSelect users={users} lead={lead} onCompleted={() => onRefetch()} />
        )
      }
    }] : []),
    ,
    {
      id: "dspSupport",
      name: i18n.t('dsp-support-archieved'),
      render: ({ node }) => {
        const lead = node?.lead
        return (
          lead.dspSupportArchieved ? <Medal style={{ filter: 'drop-shadow(0 0 28pt rgb(255 127 0 / 1))'}} size={28} /> : ''
        )
      }
    }
  ]
  let columns = allColumns
  if (displayColumns?.length > 0) {
    columns = allColumns.filter(c => displayColumns.find(dc => c.id === dc))
  }

  return (
    <>
      <NodeTable
        defaultSortByFieldId={"monthlListeners"}
        defaultSortByDirection={"desc"}
        tabIndex={0}
        emptyText={emptyText}
        emptyHeading={emptyHeading}
        emptyIcon={emptyIcon}
        expanded={expanded}
        canDelete={true}
        style={{ width: 'auto' }}
        onSelectedNodseChanged={(selectedNodeIds) => {
          setSelectedNodeIds(selectedNodeIds)
        }}
        onDeleteClicked={(nodes) => {
          onDeleteClicked(nodes)
        }}
        draggable={true}
        onNodesChecked={onNodesChecked}
        getRealNode={({ node }) => {
          return node.lead
        }}
        nodes={entries}
        hasSubRows={true}
        getValueOfNode={
          ({node, column}) => {
            return node.lead[column.id]
          }
        }
        renderSubRows={({ node, columns }) => {
          return (
            <>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td colSpan={columns.length - 2}>
                  <h3>Next contact date:  {node?.lead?.nextIntervention?.intervened ? moment(node?.lead?.nextIntervention?.intervened).format('YYYY-MM-DD') : '-'}</h3>
                  <Button type="primary" variant="contained" onClick={() => setAddingLogToLead(node?.lead)}>{i18n.t('Add note')}</Button>&nbsp;
                  <Button variant="contained" onClick={() => onCircleBackClicked(node)}>{i18n.t('schedule-next-contact')}</Button>
                  {node?.lead?.messages?.map(message => (
                    <article key={message.id}>
                      <p>{moment(message.created).fromNow()}</p>  
                      <p>{message.text}</p>
                    </article>
                  ))}
                  {/*<table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>{i18n.t('date')}</th>
                        <th>{i18n.t('message')}</th>
                        <th>{i18n.t('user')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {node?.lead?.messages?.slice(0, 2).map(message => (
                        <tr key={message.id}>
                          <th>{moment(message.sent).fromNow()}</th>
                          <th>
                            <p>{message.text}</p>
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>*/}
                </td>
              </tr>
            </>
          )
        }}
        getContextMenuItems={({ nodes }) => [
          {
            name: i18n.t('Edit'),
            onClick: () => {
              navigate(`/dashboard/lead/${nodes[0]?.lead?.slug}`)
            }
          },
          {
            name: i18n.t('Delete'),
            onClick: () => {
              onDeleteClicked(nodes.map(n => n.slug))
            }
          }
        ]}
        columns={columns}
      />
      <Dialog open={addingLogToLead} onClose={() => setAddingLogToLead(null)}>
        <DialogTitle>{i18n.t('Add log to lead %s', addingLogToLead?.name)}</DialogTitle>
        <DialogContent>
          {addingLogToLead && (
            <AddLogToLeadContainer
              slug={addingLogToLead?.slug}
              onSaved={() => {
                onRefetch()
                setAddingLogToLead(null)
              }}
            />
          )
        }
        </DialogContent>
      </Dialog>
    </>
  )
}