import { gql } from "@apollo/client";

export const USER_FIELDS = gql`
  fragment UserFields on LeadsUserEntity {
    username
    name
    firstName
    lastName
    imageUrl
  }
`

export const INTERVENTION_FIELDS = gql`
  fragment InterventionFields on LeadsInterventionNode {
    id
    intervened
  }
`

export const COUNTRY_FIELDS = gql`
  fragment CountryFields on LeadsCountryEntity {
    name
    identifier
    slug
    ...EntityFields
  }
`

export const LEAD_FIELDS = gql`
  fragment LeadFields on LeadsLeadEntity {
    nextIntervention {
      ...InterventionFields
    }
    dspSupportArchieved
    monthlyGrowthListeners
    monthlyListeners
    monthlyGrowthPercent
    imageUrl
    country {
      ...CountryFields
    }
    label {
      name
      slug
    }
    status
    socials
    spotifyUrl
    blocked
    insight
    contacted 
    name
    slug
    notes
    email
    track {
      name
      slug
      previewUrl
    }
    who {
      ...UserFields
    }
    contractValueInEur
  }
`

export const NODE_FIELDS = gql`
fragment NodeFields on LeadsEntityInterface {
  slug
}
`

export const MOODLER_ENTITY_FIELDS = gql`
  fragment MoodlerGenericEntityFields on MoodlerGenericEntityInterface {
    slug
    text
    name
  }
`

export const ENTITY_FIELDS = gql`
fragment EntityFields on LeadsEntityInterface {
  ...NodeFields
  name
}
`
  
export const LEADLIST_FIELDS = gql`
fragment LeadlistFields on LeadsLeadlistEntity {
  ...NodeFields
}
`

export const fragments = gql`
${NODE_FIELDS}
${ENTITY_FIELDS}
${USER_FIELDS} 
${COUNTRY_FIELDS}
${LEADLIST_FIELDS}
${LEAD_FIELDS}
${INTERVENTION_FIELDS}
${MOODLER_ENTITY_FIELDS}
`

export default fragments;