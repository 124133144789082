import React, { useState, useEffect } from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';

import { ErrorView } from '@/components/ErrorView';
import { loginWithMagicLink } from '@/actions/login';

import i18n from '@/i18n';

export function ReedemMagicLinkPage() {
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const { token } = useParams()
    useEffect(() => {
        loginWithMagicLink(token).then(result => {
            navigate(`/dashboard`)
        }).catch(e => {
            console.error(e)
            setError(e)
        })
    }, [token])
    if (error) {
        return (
            <Box sx={{ position: 'fixed', marginTop: 1, left: 0, top: 0, gap: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <X size={128} />
                <Typography level="h1" element="h1">{i18n.t('redeem-magic-link-error-page.heading')}</Typography>
                <Typography>{i18n.t('redeem-magic-link-error-page.text')}</Typography>
                <Button variant="solid" as={Link} to="/accounts/logout">{i18n.t('logout')}</Button>
            </Box>
        )
    }
    return (
        <form method="POST">
            {error &&
                <ErrorView />
            }
        </form>
    )
}

export default ReedemMagicLinkPage;