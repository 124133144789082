import React, { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
 
import { MusicNote } from '@mui/icons-material';

import { FingerprintView } from '@/components/FingerprintView';

import { Container, FormControl, Select, InputLabel, MenuItem, Button, Box, TextField, Input } from '@mui/material';

import { ReleaseAndCPDatesInSyncStatus } from '@/components/ReleaseAndCPDatesInSyncStatus'
import { FlaggedStatus } from '@/components/FlaggedStatus';

import { Warning } from '@mui/icons-material';
import { Check } from '@mui/icons-material';

import { useLoaderData, useLocation } from 'react-router-dom';

import { NodeTable } from '@/components/NodeTable';

import client from '@/client';
import i18n from '@/i18n';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { CheckCircle, XCircle } from 'phosphor-react';

import { Link } from '@/components';
import { EmptyView } from '../../../../components/EmptyView';
import { SpotifyLogo } from '@phosphor-icons/react';

const QUERY = gql`
  query getFingerprints(
    $isrc: String,
    $copyrighted: String,
    $hasFingerprint: String,
    $matchesArtistWatchlist: String,
    $mismatchReleaseAndCopyrightYears: String,
    $p: Int,
    $limit: Int
  ) {
    screening {
      id
      assetFingerprints(
        isrc: $isrc,
        copyrighted: $copyrighted,
        hasFingerprint: $hasFingerprint,
        matchesArtistWatchlist: $matchesArtistWatchlist
        mismatchReleaseAndCopyrightYears: $mismatchReleaseAndCopyrightYears,
        p: $p,
        limit: $limit
      ) {
        id
        meta {
          id
          pages {
            id
            count
          }
        }
        objects {
          id
          name
          isrc
          uncopyrighted
          previewUrl
          foreignIsrcCodes
          created
          originalReleaseDateIsNotDifferentFromCopyrightAndPhonographicYear
          originalReleaseDate
          cLineYear
          cLineText
          pLineYear
          pLineText
          fingerprint
          notMatchesArtistWatchlist
          originalReleaseDateMatchesCopyrightYears
          assetArtistFingerprints {
            id
            artistFingerprint {
              id
              name
            }
          }
          productFingerprint {
            id
            name
            upc
            submitted
            originalReleaseDate
            cLineYear
            cLineText
            pLineYear
            pLineText
            originalReleaseDateMatchesCopyrightYears
            notMatchesArtistWatchlist
            productArtistFingerprints {
              id
              artistFingerprint {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export function SubmissionsPage() {    
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams()
  const copyrighted = searchParams.get('copyrighted') != "null" ? searchParams.get('copyrighted') : null
  const hasFingerprint = searchParams.get('hasFingerprint') != "null" ? searchParams.get('hasFingerprint') : null
  const matchesArtistWatchlist = searchParams.get('matchesArtistWatchlist') != "null" ? searchParams.get('matchesArtistWatchlist') : null
  const mismatchReleaseAndCopyrightYears = searchParams.get('misatchReleaseAndCopyrightYears') != "null" ? searchParams.get('mismatchReleaseAndCopyrightYears') : null

  const isrc = searchParams.get('isrc') != "null" ? searchParams.get('isrc') : null

  const [error, setError] = useState(false)
  const [p, setPage] = useState(-1)
  const [pages, setPages] = useState(0)

  const [nodes, setNodes] = useState([])

  const resetPage = () => {
    setPage(0)
    return fetchPage(0, true)
  }

  useEffect(() => { resetPage() }, [search])

  const fetchPage = (p, reset=false) => {
    return client.query({
      query: QUERY,
      variables: {
        isrc,
        copyrighted,
        hasFingerprint,
        matchesArtistWatchlist,
        mismatchReleaseAndCopyrightYears,
        p: p + 1,
        limit: 100
      }
    }).then(({ data }) => {
      let baseNodes = reset ? [] : nodes
      let objects = data.screening.assetFingerprints.objects
      let newNodes = [...baseNodes, ...objects]
      setPages(data.screening.assetFingerprints.meta.pages.count)
      setNodes(newNodes)
    }).catch(e => {
      setError(e)
    })
  }

  const fetchNextPage = () => {
    setPage(p + 1)
    return fetchPage(p + 1)
  }

  useEffect(() => {fetchNextPage()}, [])

  const handleTrigger = () => {
    return fetchNextPage()
  }

  return (
    <div>
      <h1>{i18n.t('submissions')}</h1>
      <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', gap: 10 }}>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('content-status')}</InputLabel>
          <Select
            value={copyrighted}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('copyrighted', event.target.value)
                return params
              })
            }}
          > 
            <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>
            <MenuItem value={"safe"}>{i18n.t('audio-safe')}</MenuItem>      
            <MenuItem value={"copyright"}>{i18n.t('copyrighted')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('has-fingerprint')}</InputLabel>
          <Select
            value={hasFingerprint}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('hasFingerprint', event.target.value)
                return params
              })
            }}
          >
            <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>     
            <MenuItem value={"safe"}>{i18n.t('safe')}</MenuItem>      
            <MenuItem value={"flagged"}>{i18n.t('flagged-content')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('matches-artist-watchlist')}</InputLabel>
          <Select
            value={matchesArtistWatchlist}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('matchesArtistWatchlist', event.target.value)
                return params
              })
            }}
          >
            <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>   
            <MenuItem value={"safe"}>{i18n.t('safe')}</MenuItem>      
            <MenuItem value={"flagged"}>{i18n.t('matches-artist-watchlist')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('mismatch-release-and-copyright-year')}</InputLabel>
          <Select
            value={mismatchReleaseAndCopyrightYears}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('mismatchReleaseAndCopyrightYears', event.target.value)
                return params
              })
            }}
          >
            <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>   
            <MenuItem value={"safe"}>{i18n.t('safe')}</MenuItem>      
            <MenuItem value={"flagged"}>{i18n.t('matches-artist-watchlist')}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          label={i18n.t('isrc-code')}
          onChange={(event) => {
            setSearchParams(params => {
              params.set('isrc', event.target.value)
              return params
            })
          }}
          defaultValue={isrc}
          placeholder={i18n.t('isrc-code')}
        />
        <Button
          onClick={() => {
            setSearchParams(params => {
              params.delete('copyrighted')
              params.delete('hasFingerprint')
              params.delete('matchesArtistWatchlist')
              params.delete('mismatchReleaseAndCopyrightYears')
              params.delete('isrc')
            })
          }}
        >
          {i18n.t('clear-filters')}
        </Button>
      </div>
      <SubmissionsView 
        submissions={nodes}
        expanderEnabled={true}
        onTrigger={handleTrigger}
        pages={pages}
      />
    </div>
  )
}

SubmissionsPage.loader = async ({ request }) => {
  const url = new URL(request.url)
  const copyrighted = url.searchParams.get('copyrighted')
  const hasFingerprint = url.searchParams.get('hasFingerprint')
  const matchesArtistWatchlist = url.searchParams.get('matchesArtistWatchlist')
  const mismatchReleaseAndCopyrightYears = url.searchParams.get('mismatchReleaseAndCopyrightYears')
  const p = url.searchParams.get('p') ? parseInt(url.searchParams.get('p')) : 0
  const limit = url.searchParams.get('limit') ? parseInt(url.searchParams.get('limit')) : 0

  const { data } = await client.query({
    query: QUERY,
    variables: {
      copyrighted,
      hasFingerprint,
      matchesArtistWatchlist,
      mismatchReleaseAndCopyrightYears,
      p,
      limit
    }
  })
  return data.screening
}

const renderWarningIcon = ({ node }) => {
  const fingerprint = JSON.parse(node.fingerprint)
  if (fingerprint.length > 0) {
    return (
      <Warning style={{ color: '#ffaa00'}}  />
    )
  } else {
    return (
      <Check style={{ color: "#008800" }} />
    )
  }
}

export function SubmissionsView({
  submissions,
  onTrigger = () => {},
  expanderEnabled = true
}) {

  const onNodesChecked = () => {}
  const onPerformAction = () => {}

  if (submissions?.length > 0) {
    return (
      <>
        <NodeTable
          onNodesChecked={onNodesChecked}
          onPerformAction={onPerformAction}
          getRowExpanded={({ node }) => {
            const songs = JSON.parse(node?.fingerprint) ?? []
            return songs?.length > 0
          }}
          getRowShowExpander={({ node }) => {
            const songs = JSON.parse(node?.fingerprint) ?? []
            return songs?.length > 0
          }}
          canCheck={true}
          hasSubRows={true}
          renderRowClassNames={({ node }) => {    
            return !node.uncopyrighted || !node.notMatchesArtistWatchlist || !node.originalReleaseDateMatchesCopyrightYears || !node.originalReleaseDateIsNotDifferentFromCopyrightAndPhonographicYear ? 'row-warning' : ''
          }}
          emptyHeading={i18n.t('No submissions found')}
          emptyText={i18n.t('No submissions found')}
          emptyIcon={MusicNote}
    
          isRowUnavailable={(row) => {
            return false        
          }}
          toolbarButtons={() => <></>}
          sx={{
            '& .row-success': {
              bgcolor: 'rgba(0, 255, 0, .1)'
            },
            '& .row-error': {
              bgcolor: 'rgba(255, 0, 0, .1)'
            },
            '& .row-warning': {
              bgcolor: 'rgba(255, 255, 0, .1)'
            }
          }}
          actions={[ 
          ]}
          columns={[ 
            {
              id: 'name',
              name: i18n.t('name'),
              type: 'name',
              render: ({ node }) => {
                return (
                  <Link to={`/dashboard/screening/submission/${node.id}`}>{node.name}</Link>
                )
              }
            },
            {
              id: 'artists',
              name: i18n.t('artists'),
              type: 'name',
              render: ({ node }) => {
                return (
                    <>{node.assetArtistFingerprints.map(aaf => aaf.artistFingerprint.name).join(', ')}</>
                )
              }
            },
            {
              id: 'release',
              name: i18n.t('release'),
              type: 'name',
              render: ({ node }) => {
                return (
                    <>{node?.productFingerprint?.name}</>
                )
              }
            },
            {
              id: 'link',
              name: i18n.t('link'),
              type: 'custom',
              render: ({ node }) => {
                return (
                    <></>
                )
              }
            },
            {
              id: 'info',
              name: i18n.t('info'),
              type: 'array',
              render: ({ node }) => {
                const songs = JSON.parse(node?.fingerprint) ?? []
                const notMatchesArtistWatchlist = node?.notMatchesArtistWatchlist ?? node?.productFingerprint?.notMatchesArtistWatchlist

                return ( 
                  <>
                    <ul>
                      {!node.originalReleaseDateMatchesCopyrightYears && <li>{i18n.t('mismatch-copyright-years')}</li>}
                      {!node.originalReleaseDateIsNotDifferentFromCopyrightAndPhonographicYear && <li>{i18n.t('difference-between-c-p-years-and-original-release-date')}</li>}
                      {(!songs.find(s => s?.album?.label?.name === node.pLineText)) && <li>{i18n.t('possible-audio-match')}</li>}
                      {!notMatchesArtistWatchlist && (
                        <span>
                          <XCircle style={{ color: 'var(--danger)' }} color="var(--danger)" size={28} /> {i18n.t('matches-artist-watchlist')}
                        </span>
                      )}
                    </ul>
                  </>
                )
              }
            },
            {
              id: 'isrc',
              name: i18n.t('isrc'),
              type: 'text'
            },
            {
              id: 'originalReleaseDate',
              name: i18n.t('original-release-date'),
              type: 'text',
              render: ({ node }) => {
                const originalReleaseDate = node?.productFingerprint?.originalReleaseDate ?? node.originalReleaseDate
                return (
                  <span title={moment(originalReleaseDate).format('YYY-MM-DD')}>{moment(originalReleaseDate).format('YYYY-MM-DD')}</span>
                )
              }
            },
            {
              id: 'label',
              name: i18n.t('label'),
              type: 'text',
              render: ({ node }) => { 
                return (node.pLineText)
              }
            },
            {
              id: 'copyright',
              name: i18n.t('copyright'),
              type: 'text',
              render: ({ node }) => { 
                return (node.cLineText)
              }
            },
            {
              id: 'submitted',
              name: i18n.t('submitted'),
              type: 'text',
              render: ({ node }) => {
                const submitted = node?.productFingerprint?.submitted ?? node.created
                return (
                  <span title={moment(submitted).format('YYY-MM-DD')}>{moment(submitted).fromNow()}</span>
                )
              }
            }
          ]}
          renderSubRows={({ node, columns }) => {
            const songs = JSON.parse(node?.fingerprint) ?? []
            return (
              <> 
                {songs?.map((n, index) => {
                  const spotify = n?.external_metadata?.spotify?.album ?? null
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td />
                        <td />
                        <td>
                          {n?.title}  
                        </td>
                        <td>
                          {n?.album?.artists?.map(artist => (
                            <>
                              {artist.name} <br />
                            </>
                          ))}
                          {n?.artists?.map(artist => (
                            <>
                              {artist.name} <br />
                            </>
                          ))}
                        </td>
                        <td>
                          {n?.album?.name}
                        </td>
                        <td>
                          {spotify && (
                            <a target="_blank" href={`https://open.spotify.com/album/${spotify.id}?si=`}>
                              <SpotifyLogo size={28} />
                            </a>
                          )}
                        </td>
                        <td></td>
                        <td>
                          <b>{n?.external_ids?.isrc}</b>
                        </td>
                        <td></td>
                        <td>
                          {n?.label}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {/*<tr>
                        <td colSpan={10}>
                          {JSON.stringify(n)}
                        </td>
                      </tr>*/}
                    </React.Fragment>
                  )
                })}  
              </>
            )
          }}
          onTrigger={onTrigger}
          expanderEnabled={expanderEnabled}
          nodes={submissions}
        />
      </>
    )
  } else {
    return (
      <EmptyView
        heading={i18n.t('no-assets-found')}
      />       
    )
  }
}

export default SubmissionsPage