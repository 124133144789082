import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom'; 

import { createMagicLink, loginWithUsernameAndPassword } from '@/actions/login';

import i18n from '@/i18n';
import { Button, Alert, Box, Divider, TextField } from '@mui/material';
import { Warning } from '@phosphor-icons/react';
import { Check } from '@phosphor-icons/react';

export function LoginPage() {
  const navigate = useNavigate()
  const [loggingIn, setLoggingIn] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const [loggedIn, setLoggedIn] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoggingIn(true)
    localStorage.setItem('email', email);
    createMagicLink(email).then((data) => {
      setError(null)
      setLoggingIn(false)
      navigate(`/accounts/login/link/${data.id}`);
    }).catch(e => {
      console.error(e)
      setLoggingIn(false)
      setError(e)
    })
  }
  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box sx={{ padding: 1 }}>
        <Box style={{ display: 'flex', gap: 10, width: '100%', alignItems: 'stretch', flexDirection: "column"}}>
          {error &&
            <Alert variant="filled" color="danger">
              <Warning size={28} />
              {i18n.t('invalid-username-or-password')}
            </Alert>
          }
          {loggedIn &&
            <Alert variant="filled" color="success">
              <Check size={28} />
              {i18n.t('successfully-logged-in')}
            </Alert>
          }
          <TextField placeholder={i18n.t("email")} onChange={(event) => setEmail(event.target.value)} name="email" variant="standard" label={i18n.t("email")} required={true} />
          <Button loading={loggingIn} type="submit" variant="contained" color="primary">{loggingIn ? i18n.t('loading') : i18n.t('login-with-link')}</Button>
          <Divider sx={{ '--Divider-childPosition': '50%' }}>{i18n.t('or')}</Divider>
          <Button variant="outlined" onClick={() => navigate(`/accounts/login/password`)}>{i18n.t('login-with-password')}</Button>
        </Box>
      </Box>
    </form>
  )
}

export default LoginPage;
