import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useDebounce } from '@uidotdev/usehooks'

import { Button, FormControl, FormControlLabel, FormHelperText, List, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, Paper, Popover, Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';

import { Box } from '@mui/system'

import i18n from '@/i18n'
import { gql, useQuery } from '@apollo/client';
import { LoadingScreen } from './LoadingScreen';
import { VBox } from './VBox';
import { NavLink } from 'react-router-dom';
import { ListBullets, MusicNote, PaperPlane } from 'phosphor-react';
import { Img } from './Img';
import {  Paperclip, Person, Playlist, User } from '@phosphor-icons/react';
import { IndeterminateCheckBoxSharp } from '@mui/icons-material';
import { IndeterminateProgressBar } from './IndeterminateProgressBar';

const QUERY = gql`
  query search($q: String!) {
    search {
      query(
        q: $q
      ) {
        result {
          objects {
            id
            name
            type
            uri
          }
        }
      }
    }
  }
`

function NodeIcon({
  type,
  ...props
}) {
  let Icon = Paperclip
  switch (type) {
    case "leadlist":
      Icon = Playlist
      break
    case "lead":
      Icon = PaperPlane
      break
    case "user":
      Icon = User
      break
    case "assetFingerprint":
      Icon = MusicNote
      break
  }
  return <Icon {...props} />
}

export function SearchBox({
  onSearch
}) {
  const [searchQuery, setSearchQuery] = useState('')


  const handleSubmit = event => {
    event.preventDefault()
    onSearch?.(searchQuery)
    return false
  }

  useEffect(() => {
    if (searchQuery?.length > 0) {
      setShowResults(true)
    }
  }, [searchQuery])

  const searchRef = useRef(null)

  const [showResults, setShowResults] = useState(false)

  const q = useDebounce(searchQuery, 1000)

  const { data, loading } = useQuery(QUERY, {
    variables: {
      q
    }
  })
  const nodes = data?.search?.query?.result?.objects ?? []

  return (
    <form ref={searchRef} onSubmit={handleSubmit} style={{ gap: 3, flex: 3, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <FormControl sx={{ flex: 1, width: '100%' }} variant="outlined">
        <OutlinedInput
          defaultValue={q}
          style={{ flex: 1 }}
          placeholder="Search (/) for leads, fingferprints, isrcs and more"
          onChange={(event) => setSearchQuery(event.target.value)} 
        />
      </FormControl>
      <FormControl  variant="outlined">
   
      <Button
        variant="outlined"
        type="submit"
      >{i18n.t('search')}</Button>
      </FormControl>
      <Popover
        id="search-results"
        open={showResults}
        onClose={() => setShowResults(false)}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorEl={searchRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ 
          '& .MuiPaper-root': {
            width: searchRef?.current?.offsetWidth
          },
          width: '100%'
        }}
      >
        {loading ? (
          <IndeterminateProgressBar />
        ) : (
          <List>
            {nodes?.length > 0 ? (
              nodes.map(node => (
                <ListItemButton
                  component={NavLink}
                  disablePadding
                  onClick={() => setShowResults(false)}
                  to={`/dashboard/${node?.uri.split(/:/).slice(1).join('/')}`}
                >
                  <ListItemIcon>
                    {node?.imageUrl ? (
                      <Img src={node?.imageUrl} />
                    ): 
                      <NodeIcon size={28} type={node.type} />
                    }
                    <ListBullets color="white" size={25} />
                  </ListItemIcon>
                
                  <ListItemText primary={node.name} secondary={node.type} />
                </ListItemButton>
              ))
            ) : (
              <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>{i18n.t('no-results-found-for-q', { q })}</Typography>
              </Box>
            )}
          </List>
        )}
      </Popover>
    </form>
  )
}