import React from 'react';

import styles from './index.module.scss';

export function ProgressBar({
  value = 0,
  max = 100
}) {
  const percent = Math.round((value / max) * 100)
  return (
    <div className={styles.progressBar}>
      <div
        className={styles.progressBarThumb}
        style={{ width: `${percent}%`}}
      />
    </div>
  )
}