import React, { useMemo } from 'react';

import { useLoaderData } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import { CopyrightMatchStatus } from '@/components/CopyrightMatchStatus';


import { FingerprintView } from '@/components/FingerprintView';

import client from '@/client';

import { gql, useMutation } from '@apollo/client';
import { Box, Paper, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import i18n from '@/i18n';

const QUERY = gql`query getSubmission($submissionId: ID!) {
  moodler {
    users {
      username
      id
    }
  }
  screening {
    assetFingerprint(assetFingerprintId: $submissionId) {
      name
      id
      isrc
      fingerprint
      uncopyrighted
      previewUrl
    }
  }
}`

const MUTATION = gql`mutation editSubmission(
  $submissionId: ID!
) {
  editAssetFingerprint(
    submissionId: $submissionId
  ) {
    submission {
      id
    }
  }
}`

export function SubmissionOverviewPage() {
  const submission = useLoaderData() 
  return <SubmissionOverview submission={submission} />
}

export function SubmissionOverview({
  onUpdated = () => {},
  submission
}) {
  const [
    updateSubmission,
    {
      loading: updating
    }
  ] = useMutation(
    MUTATION, {
      onCompleted: () => {
        enqueueSnackbar(i18n.t('submission updated'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(i18n.t('Sorry, an error occurred when updating the submission'), { variant: 'error' })
      }
    }
  )

  const fingerprint = useMemo(() => JSON.parse(submission?.fingerprint), [submission])
 
  const handleSubmit = (event) => {
    event.preventDefault()
    updateSubmission({
      variables: {
        submissionId: submission.id
      }
    }).then(() => {
      /*refetch().then(() => {
        onUpdated()
      })*/
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ padding: 1}}> 
        <fieldset>
          <legend>{i18n.t('submission-details')}</legend>
          <Paper sx={{padding: 1, flexDirection: 'column'}}>
            <CopyrightMatchStatus uncopyrighted={submission.uncopyrighted} />
            <TextField
              fullWidth
              variant="standard"
              label="Name"
              readOnly
              value={submission?.name} 
            />
          </Paper>
        </fieldset>
        <fieldset>
          <legend>Uploaded track</legend>
          <Paper sx={{padding: 1, flexDirection: 'column'}}>
            <label>Preview</label>
            {submission.previewUrl ? <audio controls src={submission.previewUrl} /> : 'no-preview-availble'}
          </Paper>
        </fieldset>
        <fieldset>
          <legend>Matched track</legend>
          <Paper sx={{padding: 1, flexDirection: 'column'}}>
            {fingerprint?.map(fingerprint => (
              <FingerprintView fingerprint={fingerprint} />
            ))}
          </Paper>
        </fieldset>
      </Box>
      <Box sx={{ marginTop: 1, alignItems: 'center', display: 'flex', flexDirection: 'row', gap: 2}}>
        {/*<LoadingButton loading={updating} type="submit" variant="contained">{i18n.t('save')}</LoadingButton>*/}
      </Box>
    </form>
  )
}

SubmissionOverviewPage.loader = async ({ params: { submissionId }}) => {
  const { data } = await client.query({
    query: QUERY,
    variables: {
      submissionId
    }
  })
  return data?.screening?.assetFingerprint ?? null
}

export default SubmissionOverviewPage;
