import React from 'react'

import { Route, Outlet, Navigate } from "react-router-dom";

import AccountsLayout from "./layout";
import LoginPage from "./login";
import LogoutPage from "./logout";
import LoginLinkPage from "./login/link";
import RedeemMagicLinkPage from './login/redeem';
import LoginWithPasswordPage from './login/password';
import LoginLayout from './login/layout';
import { CallbackPage } from '../callback';

export default (
    <>
        <Route path="callback" element={<CallbackPage />} />
        <Route path="login" element={<Navigate to="/accounts/login" />} />
        <Route path="logout" element={<Navigate to="/accounts/logout" />} />
        <Route path="accounts" element={<AccountsLayout />}>
            <Route index element={<Navigate to="login" replace />} />
            <Route path="magic" element={<Outlet />}>
                <Route path="link" element={<Outlet />}>
                    <Route path=":token" element={<RedeemMagicLinkPage />} />
                </Route>
            </Route>
            <Route path="link" element={<Outlet />}>
                <Route path=":linkId" element={<LoginLinkPage />} />
            </Route>
            <Route path="login" element={<LoginLayout />}>
                <Route index element={<LoginPage />} />
                <Route path="password" element={<LoginWithPasswordPage />} />
                <Route path="link" element={<Outlet />}>
                    <Route path=":linkId" element={<LoginLinkPage />} />
                </Route>
            </Route>
            <Route path="logout" element={<LogoutPage />} />
        </Route>
    </>
)
