import React from 'react'

import {
  Route,
  createBrowserRouter,
  Outlet,
  Navigate,
  createRoutesFromElements
} from "react-router-dom";
import Root from "./pages/root";

import StreamingScreeningPage from './pages/dashboard/screening/streaming'

import FinishUploadPage from './pages/dashboard/upload/[slug]/finish';
import { DiscoverPage } from './pages/dashboard/discover';
import { LibraryLayout } from './pages/dashboard/library/layout';
import LibraryOverviewPage from './pages/dashboard/library/overview';
import { PlaylistsLibraryPage } from './pages/dashboard/library/playlists';
import LabelListPage from './pages/dashboard/labels';
import PlaylistOverviewPage from './pages/dashboard/playlist/[slug]/overview'
import { LabelLayout } from './pages/dashboard/label/[slug]/layout';
import { EditLabelPage } from './pages/dashboard/label/[slug]/edit';
import { LeadLayout } from './pages/dashboard/lead/[slug]/layout';
import { EditLeadOverviewPage } from './pages/dashboard/lead/[slug]/overview';
import { EditLeadMessagesPage } from './pages/dashboard/lead/[slug]/messages';
import { ImportOverviewPage } from './pages/dashboard/import/overview';
import LeadlistOverviewPage from './pages/dashboard/leadlist/overview';
import LeadlistLayout from './pages/dashboard/leadlist/layout';
import LabelListLayout from './pages/dashboard/labels/layout';
import SearchPage from './pages/dashboard/search';
import SearchLayout from './pages/dashboard/search/layout';
import { SearchResultLayout } from './pages/dashboard/search/[q]/layout';
import SearchResultOverviewPage from './pages/dashboard/search/[q]';
import PlaylistLayout from './pages/dashboard/playlist/[slug]/layout'
import NotificationsPage from './pages/dashboard/notifications';

import QueueLayout from './pages/dashboard/queue/layout';
import { QueueOverviewPage } from './pages/dashboard/queue/overview';
import { UploadLayout } from './pages/dashboard/upload/[slug]/layout';
import { UploadFieldsPage } from './pages/dashboard/upload/[slug]/fields';
import { UploadOverviewPage } from './pages/dashboard/upload/[slug]/overview';
import { ReviewUploadPage } from './pages/dashboard/upload/[slug]/review';

import LeadNotificationsPage from './pages/dashboard/lead/[slug]/notifications';

import ImportUploadsPage from './pages/dashboard/import/uploads';
import ImportLayout from './pages/dashboard/import/layout';

import StatisticsPage from './pages/dashboard/statistics'

import DashboardLayout from './pages/dashboard/layout';
import DashboardOverviewPage from './pages/dashboard/overview';
import LIAPage from './pages/legal/lia';
import LegalLayout from './pages/legal/layout';
import SubmissionsPage from './pages/dashboard/screening/submissions';
import SubmissionOverviewPage from './pages/dashboard/screening/submission/[submissionId]/overview';
import {CreateLeadPage} from "./pages/dashboard/lead/create";

import OrganizationSubmissionsPage from './pages/dashboard/screening/organization/[organizationId]/submissions';
import OrganizationsLayout from './pages/dashboard/screening/organizations/layout';
import OrganizationsIndexPage from './pages/dashboard/screening/organizations';

import accountRoutes from './pages/accounts/routes';
import StitchAppPage from './pages/dashboard/app/[appId]';
import AppFinderPage from './pages/dashboard/app/finder';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Navigate to="/accounts/login" />} />
      <Route path="legal" element={<LegalLayout />}>
        <Route path="lia" element={<LIAPage />} />
      </Route>
      {accountRoutes}
      <Route path="dashboard" loader={DashboardLayout.loader} element={<DashboardLayout />}>
        <Route index element={<Navigate to="overview" replace={true} />} />
        <Route path="overview" element={<DashboardOverviewPage />} />
        <Route path="app" element={<Outlet />}>
          <Route path="finder" element={<Outlet />}>
            <Route index element={<AppFinderPage />} />
            <Route path="app" element={<Outlet />} />
          </Route>
          <Route path=":slug" element={<Outlet />}>
            <Route index element={<StitchAppPage />} />
            <Route path="*" element={<StitchAppPage />} />
          </Route> 
        </Route>
        <Route path="notifications" element={<NotificationsPage />} />
        <Route path="discover" element={<DiscoverPage />} />
        <Route path="search" element={<SearchLayout />}>
          <Route index element={<SearchPage />} />
          <Route path=":q" element={<SearchResultLayout />}>
            <Route index element={<SearchResultOverviewPage />} />
          </Route>
        </Route>
        <Route path="statistics" element={<Outlet />}>
            <Route index element={<StatisticsPage />} />
        </Route>
        <Route path="playlists" element={<PlaylistsLibraryPage />} />
        <Route path="library" element={<LibraryLayout />}>
          <Route index element={<LibraryOverviewPage />} />
          <Route path="playlists" element={<PlaylistsLibraryPage />} />
        </Route>
        <Route path="queue" element={<QueueLayout />}>
          <Route
          index
          element={<QueueOverviewPage />}
          />
        </Route>
        <Route path="leadlist" element={<LeadlistLayout />}>
          <Route path=":slug" element={<LeadlistOverviewPage />} />
        </Route>
        <Route path="import" element={<ImportLayout />}>
          <Route index element={<Navigate to="overview" replace={true} />} />
          <Route path="overview" element={<ImportOverviewPage />} />
          <Route path="uploads" element={<ImportUploadsPage />} />
        </Route>
        <Route path="label" element={<Outlet />}>
          <Route path=":slug" element={<LabelLayout />}>
            <Route index element={<Navigate to="edit" replace={true} />} />
            <Route path="edit" loader={EditLabelPage.loader} element={<EditLabelPage />} />
          </Route>
        </Route>
        <Route path="labels" element={<LabelListLayout />}>
          <Route index element={<LabelListPage />} />
        </Route>
        <Route path="lead" element={<Outlet />}>
          <Route path="create" element={<CreateLeadPage />} />
          <Route path=":slug" element={<LeadLayout />}>
            <Route index element={<Navigate to="edit" replace={true} />} />
            <Route path="edit" element={<EditLeadOverviewPage />} />
            <Route path="messages" element={<EditLeadMessagesPage />} />
            <Route path="notifications" element={<LeadNotificationsPage />} />
          </Route>
        </Route>
        <Route path="screening" element={<Outlet />}>
          <Route path="streaming" element={<StreamingScreeningPage />} />
          <Route index element={<Navigate to="submissions" />} />
          <Route path="organizations" element={<OrganizationsLayout />}>
            <Route index element={<OrganizationsIndexPage />} />
            <Route path=":organizationId" element={<Outlet />}>
              <Route index element={<Navigate to="submissions" replace />} />
              <Route path="submissions" loader={OrganizationSubmissionsPage.loader} element={<OrganizationSubmissionsPage />} />
            </Route>
          </Route>
          <Route path="submissions" element={<SubmissionsPage />} />
          <Route path="submission" element={<Outlet />}>
            <Route path=":submissionId" element={<Outlet />}>
              <Route index element={<Navigate replace to="overview" />} />
              <Route path="overview" element={<SubmissionOverviewPage />} loader={SubmissionOverviewPage.loader } />
            </Route>
          </Route>
        </Route>
        <Route path="upload" element={<Outlet />}>
          <Route path=":slug" loader={UploadLayout.loader} element={<UploadLayout />}>
            <Route index element={<Navigate to="overview" replace={true} />} />
            <Route path="overview" loader={UploadOverviewPage.loader} element={<UploadOverviewPage />} />
            <Route path="fields" loader={UploadFieldsPage.loader} element={<UploadFieldsPage />} />
            <Route path="review" loader={ReviewUploadPage.loader} element={<ReviewUploadPage />} />
            <Route path="finish" loader={FinishUploadPage.loader} element={<FinishUploadPage />} />
          </Route>
        </Route>
        <Route element={<PlaylistLayout />} path="playlist/:slug">
          <Route index element={<Navigate to="overview" replace={true} />} />
          <Route element={<PlaylistOverviewPage />} path="overview" />
        </Route>
      </Route>
    </Route>
  ) 
);

export default router
