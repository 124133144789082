import React, { useEffect, useRef, useState } from 'react';

import { LoadingScreen } from '../LoadingScreen';
import { ProgressBar } from '../ProgressBar';

import styles from './index.module.scss';
import { gql, useQuery } from '@apollo/client';
import { LoadingPage } from '../LoadingPage';
import { ErrorView } from '../ErrorView';
import { VBox } from '../VBox';
import { HBox } from '../HBox';
import { Box, Typography } from '@mui/material';
import { InstallAppButton } from '../InstallAppButton';

import i18n from '@/i18n'

const QUERY = gql`
  query getAppById($slug: String!) {
    appfinder {
      app(slug: $slug) {
        id
        slug
        name
        canvasUrl
        installation {
          id
          installed
        }
      }
    }
  }

`

export function StitchApp({
  uri,
  onNavigate,
  onLoad,
  origin = null,
  defaultQuery = {},
  onMessage,
  style,
  ...props

}) {
  const [loaded, setLoaded] = useState(0)
  const iframeRef = useRef(null)
  const parts = uri.split(/:/)
  const slug = parts[2]
  const params = parts.slice(3)
  let mutationObserver = null

  let loadingInterval 

  const [query, setQuery] = useState(defaultQuery)

  const { data, refetch, fetchMore, loading } = useQuery(
    QUERY,
    {
      variables: {
        slug
      }
    }
  )
  const handleMessage = (action, data) => {
    if (onMessage instanceof Function) {
      onMessage(action, data);
    }
    if (action === 'load') {
      handleLoad(data.value ?? 100)
    }
  }

  const handleLoad = (value) => {
    setLoaded(120)
  }

  useEffect(() => {
    loadingInterval = setInterval(() => {
     setLoaded((prevLoaded) => prevLoaded + 10)
      if (loaded >= 120) {
        clearInterval(loadingInterval)
      }
  
    }, 200)
  }, [])
   
  useEffect(() => {
    if (!iframeRef.current) return;
    if (iframeRef?.current?.postMessage instanceof Function) {
      iframeRef.current.contentWindow.postMessage({
        params
      }, origin)
    }
  }, [iframeRef.current])
  useEffect(() => {
    if (!iframeRef.current) return;
    /*iframeRef.current.contentWindow.addEventListener('DOMContentLoaded', (event) => {
      
      debugger
      setLoaded(50);
    }, origin ?? window.location.hostname)
    iframeRef.current.contentWindow.addEventListener('load', (event) => {
      debugger
      setLoaded(100);
    }, origin ?? window.location.hostname)*/
    if (typeof window !== 'undefined') {
      window.addEventListener('message', (event) => {
        if (event.data?.action === 'load') {
          if (onLoad instanceof Function) {
            onLoad();
          }
        } 
        handleMessage(
          event.data.action,
          event.data
        ) 
      })
    }
  }, [iframeRef.current])
  useEffect(() => {
    if (!iframeRef.current) return;
    mutationObserver = new MutationObserver((mutations) => {
      mutations.some((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
       
          console.log('Old src: ', mutation.oldValue);
          console.log('New src: ', mutation.target.src);
          let pathname = mutation.target.src.split(/\//, 3)[3]
          if (onNavigate instanceof Function) {
            onNavigate(pathname.split(/\//g))
          }
          return true;
        }
    
        return false;
      });
    }).observe(
      iframeRef.current, {
        attributes: true,
        attributeFilter: ['src'],
        attributeOldValue: true,
        characterData: false,
        characterDataOldValue: false,
        childList: false,
        subtree: true
      }
    )
  }, [iframeRef.current])

  if (loading) {
    return (
      <LoadingPage />
    )
  }
  if (!data) {
    return (
      <ErrorView />
    )
  }

  const app = data.appfinder.app

  if (app) {
    return (
      <VBox sx={{ gap: 0}}>
        {!(app.installation) && (
          <HBox sx={{ p: 1, borderBottom: '1pt solid rgba(127, 127, 127, .5)'}}>
            <Box sx={{ flex: 1 }}>
              <Typography>{i18n.t('add-a-shortcut-to-this-app-by-clicking-install')}</Typography>
            </Box>
            <InstallAppButton onChange={() => refetch()} slug={app.slug} />
          </HBox>
        )}
        <div style={{position: 'relative', flex: 1, ...style}}>
          <iframe 
            ref={(ref) => iframeRef.current = ref}
            frameborder="0"
            src={`${[app.canvasUrl, ...params].join('/')}?${new URLSearchParams(query).toString()}`}
            allowTransparency={true}
            allowFullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            style={{visibility: loaded >= 120 ? 'visible': 'hidden', position: 'absolute', left: 0,  width: '100%', height: '100vh', top: 0, flex: 1}}
          ></iframe>
          {loaded <= 120 &&
          <div style={{visibility: loaded <= 120 ? 'visible' : 'hidden'}} className={styles.stichAppLoading}>
            <ProgressBar value={loaded} />
          </div>}
        </div>  
      </VBox>
    )
  } else {
    return <LoadingScreen />
  }
}