import { StitchApp } from '@/components/StitchApp'

import React from 'react'
import { useParams } from 'react-router'

export function StitchAppPage() {
  const { slug } = useParams()

  return (
    <StitchAppContainer slug={slug} />
  )
}

export function StitchAppContainer({
  slug
}) {
  return (
    <StitchApp
      uri={`aloaded:app:${slug}`}
    />
  )
}

export default StitchAppPage