import color from 'color'

import brand from './brand';

export const themeOptions = (mode = 'light') => ({
  palette: {
    mode: mode === 'dark' ? 'dark' : 'light',
    background: {
      default: mode === 'yellow' ? brand.colors.primary : '#fff'
    },
    /*subdued: {
      main: '#6a6a6a',
    },*/
    primary: {
      main: mode === 'dark' ? brand.colors.primary : '#555555',
    },
    info: {
      main: '#0288d1',
    },
    error: {
      main: '#e91429',
    },
    secondary: {
      main: '#6a6a6a',
    },
    light: {
      main: '#ffffff',
    },
    inherit: {
      main: mode === 'dark' ? '#aaa' : '#888'
    },
    text: {
      primary: '#000', // Helvetica Neue,Arial,sans-serif
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& .MuiDataGrid-footerContainer': {
            borderTop: mode === 'yellow' ? '1px solid black' : undefined,
            borderBottom: mode === 'yellow' ? '1px solid black' : undefined
          }
        },
        main: { 
          borderRadius: 0,
          border: 'none',
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0,
            borderBottom: mode === 'yellow' ? '1px solid black' : undefined,
            borderTop: mode === 'yellow' ? '1px solid black' : undefined,
            borderRight: mode === 'yellow' ? '1px solid black' : undefined,
            '&:last-child': {
              borderRight: 'none',
              borderLeft: 'none',
            },
            '&:first-of-type': {
              borderLeft: 'none',
              borderRight: 'none',
            }
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-cell': {
            borderBottom: mode === 'yellow' ? '1px solid black' : undefined
          },
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({theme }) => ({
          backgroundColor: mode === 'yellow' ? brand.colors.primary : undefined
        })
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          padding: 1,
          background: 'rgba(127, 127, 127, .25)'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          },
          color: theme.palette.mode === 'dark' ? '#fff': '#000'
        })
      },
      defaultProps: {
       
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,

        },
      },
      defaultProps: {
        color: 'default',
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: false,
        disableFocusRipple: false,
      },
      styleOverrides: {
        /*contained: ({ theme }) => ({
          borderRight: '1px solid #888888', 
          borderBottom: '1px solid #888888', 
          borderLeft: '1px solid #cccccc',
          borderTop: '1px solid #cccccc',
          background: `linear-gradient(180deg, #ffffff, #eeeeee)`
        }),
        containedSuccess: ({ theme }) => ({
          borderRight: '1px solid ' + color(theme.palette.success.main).darken(0.15).string(), 
          borderBottom: '1px solid ' + color(theme.palette.success.main).darken(0.15).string(), 
          borderLeft: '1px solid ' + color(theme.palette.success.main).lighten(0.85).string(),
          borderTop: '1px solid ' + color(theme.palette.success.main).lighten(0.85).string(),
          background: `linear-gradient(180deg, ${color(theme.palette.success.main).lighten(0.50).string()}, ${color(theme.palette.success.main).lighten(0.30).string()})`
        }),
        containedPrimary: ({ theme }) => ({
          borderRight: '1px solid ' + color(theme.palette.primary.main).darken(0.25).string(), 
          borderBottom: '1px solid ' + color(theme.palette.primary.main).darken(0.25).string(), 
          borderLeft: '1px solid ' + color(theme.palette.primary.main).lighten(0.55).string(),
          borderTop: '1px solid ' + color(theme.palette.primary.main).lighten(0.55).string(),
          background: `linear-gradient(180deg, ${color(theme.palette.primary.main).lighten(0.30).string()}, ${color(theme.palette.primary.main).lighten(0.10).string()})`
        })*/
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: '0 5pt 20pt -3pt rgba(0, 0, 0, .1)',
          }
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    }, 
    MuiCard: {
      styleOverrides: {
        root: {
          '&.card': {
            boxShadow: 'rgb(0 0 0 / 30%) 0px 2px 6px',
          },
          borderRadius: '8px',
          padding: '24px',
          '&:hover': {
            boxShadow: '0 5pt 20pt -3pt rgba(0, 0, 0, .1)',
          },
          '&.special': {
          },
          '& .MuiCardContent': {
            padding: 0,
          },
          '&.gradient': {
            boxShadow: '0 5pt 8pt 0pt var(--shadow-color)',
            '&:hover': {
              boxShadow: '0 5pt 20pt -3pt var(--shadow-color)',
            },
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          '&.Mui-hover': {
            transform: 'scale(1.05)',
          },
          '&.Mui-active': {
            transform: 'scale(0.99)',
          },
          boxShadow: 'none',
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      fontSize: '8rem',
    },
    h2: {
      fontWeight: 'bold',
      color: 'gray',
      fontSize: '4rem',
    },
  },
  shape: {
    borderRadius: 4,
  },  
});