import React from 'react';

import { SvgIcon } from '@mui/material';

import OpenSidebarSVG from '../images/OpenSidebar.svg?react';

export function OpenSidebar({
  ...props
}) {
  return (
    <SvgIcon component={OpenSidebarSVG} {...props} inheritViewBox />
  )
}