import React from 'react';

import moment from 'moment';

import { Box, Typography } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

export default function AccountsLayout({ children }) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ flex: 1 }}></Box>
        <img width="300pt" />
        <Box style={{ flex: 1 }}></Box>
        <Outlet />
        <Box sx={{ flex: 1 }}></Box>
        <small style={{ margin: 10, opacity: 0.5 }}>Copyright &copy; {moment().format('YYYY')} ALOADED AB. All Rights Reserved.</small>
      </Box>
    )
}
