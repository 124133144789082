import React from 'react'

import { useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorView } from '@/components/ErrorView'

import { Typography, Box, Container, CircularProgress } from '@mui/material'

import i18n from '@/i18n'
import { NotificationCard } from '@/components/NotificationCard'

export default function LeadNotificationsPage() {
    const { slug } = useParams()
    return (
        <React.Suspense fallback={<></>}>
            <LeadNotifications slug={slug} />
        </React.Suspense>
    )
}

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($slug: String!) {
    markNotificationAsRead(slug: $slug) {
      notificationRead {
        slug
      }
    }
  }
`

const MARK_NOTIFICATION_AS_UNREAD = gql`
  mutation markNotificationAsUnread($slug: String!) {
    markNotificationAsUnread(slug: $slug) {
      ok
    }
  }
`

const QUERY = gql`query getLead($slug: String!) {
  moodler {
    users {
      username
      id
    }
  } 
  leads {
    lead(slug: $slug) {
      slug
      name
      spotifyUrl
      monthlyListeners
      country {
        identifier
        name
      }
      monthlyGrowthPercent
      imageUrl
      track {
        name
        slug
        previewUrl
      }
      notifications {
        slug
        isRead
        created
        subject {
          username
          imageUrl
        }
        predicate,
        fieldId
        value
        indirectNode {
          slug
          name
          text
          type
        }
        node {
          slug
          name
          text
          type
        }
      }
    }
  }
}`

export function LeadNotifications({
    slug
}) {
    const { data, refetch, loading, error } = useQuery(QUERY, {
        variables: {
            slug
        }
    })

    const [markNotificationAsUnread] = useMutation(MARK_NOTIFICATION_AS_UNREAD)
    const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ)

    const notifications = data?.leads?.lead?.notifications

    if (!notifications) {
        return (
            <ErrorView />
        )
    }

    return (
        <Container>
            <Box sx={{ padding: 1 }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: { sm: 'column', lg: 'row' } }}>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <Typography variant="h5">{i18n.t('Lead info')}</Typography>
                        {error ?
                            <p>There was an error loading</p>
                        : loading ?
                            <CircularProgress />
                        : notifications?.length > 0 ? notifications.slice(0, 3).map(notification => (
                            <NotificationCard
                                onToggleReadClick={() => {
                                    if (notification.isRead) {
                                        markNotificationAsUnread({
                                            variables: {
                                                slug: notification.slug
                                            }
                                        }).then(() => {
                                            refetch().then(() => { })
                                        })
                                    } else {
                                        markNotificationAsRead({
                                            variables: {
                                                slug: notification.slug
                                            }
                                        }).then(() => {
                                            refetch().then(() => { })
                                        })
                                    }
                                }}
                                {...notification}
                            />
                        )) :
                            <Typography variant="h2">{i18n.t('No messages found')}</Typography>
                        }
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
