import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { CircularProgress, FormControl, Select, MenuItem, InputLabel, Avatar, Typography, Box } from '@mui/material';

import i18n from '../../i18n';

export const ASSIGN_LEAD_MUTATION = gql`mutation assignLead(
  $slug: String!,
  $username: String!
) {
  assignLead(slug: $slug, username: $username) {
    lead {
      slug
    }
  }
}`

export function AssignLeadSelect({
  lead,
  users = [],
  onCompleted = () => {}
}) {
  const [assignLead, { loading }] = useMutation(ASSIGN_LEAD_MUTATION, {
    onCompleted: () => {
      onCompleted()
    }
  })
  if (loading) {
    return (
      <CircularProgress />
    )
  }
  return (
    <FormControl fullWidth>
      <InputLabel>{i18n.t('Who')}</InputLabel>
      <Select
        id="select-who"
        variant="outlined"
        value={lead.who?.username}
        sx={{
          width: '100%'
        }}
        onChange={(event) => {
          assignLead({
            variables: {
              slug: lead.slug,
              username: event.target.value
            }
          })
        }}
      >
        <MenuItem value={null}>{i18n.t('Unassigned')}</MenuItem>
        {users?.map(user => (
          <MenuItem key={user.username} value={user.username}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar sx={{ width: 28, height: 28 }} src={user.imageUrl} />&nbsp;<Typography>{user.firstName} {user.lastName}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}