import React, { useEffect, useState } from 'react';

import { Outlet, useLoaderData, useParams, NavLink } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import client from '../../../../client'

import dayjs from 'dayjs'
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Avatar, Tabs, Tab, Button, CircularProgress, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';

import i18n from '../../../../i18n';
import { UserView } from '../../../../components/UserView';

import { LEAD_STATUS_CODES } from '../../../../constants';

import { NavigationFooter } from '../../../../components/NavigationFooter';
import { LoadingPage } from '../../../../components/LoadingPage';
import { TrackPlayButton } from '../../../../components/TrackPlayButton';
import { SpotifyLogo } from 'phosphor-react';
import { OpenInNew } from '@mui/icons-material';
import { AvatarPlayButton } from '../../../../components/AvatarPlayButton';
import { Backdrop } from '../../../../components/Backdrop';
import { Img } from '../../../../components/Img';

const QUERY = gql`query getLead($slug: String!) {
  moodler {
    users {
      username
      id
    }
  }
  leads {
    lead(slug: $slug) {
      slug
      name
      spotifyUrl
      monthlyListeners
      country {
        identifier
        name
      }
      monthlyGrowthPercent
      imageUrl
      track {
        name
        slug
        previewUrl
      }
    }
  }
}`

const MUTATION = gql`mutation editLead(
  $slug: String!,
  $isBlocked: Boolean,
  $notes: String,
  $email: String,
  $whoUsername: String,
  $contacted: Date,
  $shouldContact: Date,
  $insight: String,
  $socials: String,
) {
  editLead(
    slug: $slug,
    notes: $notes,
    isBlocked: $isBlocked,
    email: $email,
    contacted: $contacted,
    shouldContact: $shouldContact,
    whoUsername: $whoUsername,
    insight: $insight,
    socials: $socials
  ) {
    lead {
      slug
    }
  }
}`

function samePageLinkNavigation(
  event
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

function LinkTab(props) {
  return (
    <Tab
      component={NavLink}
      {...props}
    />
  );
}

export function LeadLayout({
  onUpdated = () => {}
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(
          event
        ))
    ) {
      setValue(newValue);
    }
  };
  const { slug } = useParams()
  const { data, loading } = useQuery(QUERY, {
    variables: {
      slug
    }
  })
  if (loading) {
    return (
      <LoadingPage />
    )
  }
  const lead = data?.leads?.lead
  if (!lead) {
    return (
      <span>Error</span>
    )
  }
  return (
    <Container>
      <Backdrop src={lead.imageUrl} />
      <Box sx={{ padding: 1 }}>
        <Box sx={{display: 'flex', gap: 1, flexDirection: { sm: 'column', lg: 'row'}}}>
          <Box sx={{ display: 'flex', flex: 1, gap: 1, flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 1}}>
              <Avatar src={lead.imageUrl} style={{ width: '128pt', height: '128pt'}} />
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end', gap: 1}}>
                <small>{i18n.t('lead')} <Img
                    alt={lead?.country?.name}
                    fallbackSrc={null}
                    src={`https://flagcdn.com/32x24/${lead?.country?.identifier?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/32x24/${lead?.country?.identifier?.toLowerCase()}.png 2x,
                          https://flagcdn.com/48x36/${lead?.country?.identifier?.toLowerCase()}.png 3x`}

                  /></small>
                <h1 style={{ flex: 1 }}>
                  {lead.name}
                </h1>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                  <TrackPlayButton style={{ color: '#00ff00'}} size={64} sticky={true} node={lead} value={lead.track} />
                  <Button
                    color="success"
                    sx={{
                      borderRadius: 28
                    }}
                    variant="contained"
                    onClick={() => window.open(lead.spotifyUrl)}
                  ><SpotifyLogo size={28} weight="fill" /> Spotify <OpenInNew color="white" /></Button>
                </Box>
               </Box>
            </Box>
            <Box sx={{ opacity: 0.5, display: 'flex', alignItems: 'center', gap: 1, flexDirection: 'row' }}>
              {lead?.label?.name ?? i18n.t('unsigned')} · {lead.monthlyListeners?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0})} {i18n.t('Monthly listeners')} · {lead.monthlyGrowthPercent?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}% {i18n.t('Change')}
            </Box>
            <Tabs value={value} onChange={handleChange}>
              <LinkTab label={i18n.t('overview')} to="edit" />
              <LinkTab label={i18n.t('notifications')} to="notifications" />
              <LinkTab label={i18n.t('messages')} to="messages" />
            </Tabs>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default LeadLayout;
