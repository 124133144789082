import React from 'react'

import { CaretUp, CaretDown, Circle } from 'phosphor-react'

export function TrendArrow({
    value
}) {
    
    const Arrow = value != 0 ? ( value > 0 ? CaretUp : CaretDown) : Circle
    const color = value != 0 ? ( value > 0 ? 'green' : 'red') : 'black'
    return <Arrow size={16} weight="fill" color={color} />
}