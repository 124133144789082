import React from 'react';

import { SvgIcon } from '@mui/material';

import CloseSidebarSVG from '../images/CloseSidebar.svg?react';

export function CloseSidebar({
  ...props
}) {
  return (
    <SvgIcon component={CloseSidebarSVG} {...props} inheritViewBox />
  )
}
